// src/routes.js
import { createRouter, createWebHistory } from 'vue-router';


// Importez les composants associés à chaque route
import Home from './views/Home.vue';

import LoginForm from './views/LoginForm.vue';
import RegisterFrom from './views/RegisterFrom.vue';

import LoginLayout from './layouts/LoginLayout.vue';
import RegisterLayout from './layouts/RegisterLayout.vue';


const routes = [
  { path: '/', component: Home },

  {
    path: '/auth/login',
    component: LoginForm,
    meta: { layout: LoginLayout, public: false },
  },
  {
    path: '/auth/register',
    component: RegisterFrom,
    meta: { layout: RegisterLayout, public: false }
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;